import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.css"
import VueQrcodeReader from 'vue3-qrcode-reader'
// import "bootstrap-icons/font/fonts/bootstrap-icons.css"
// import { library } from "@fortawesome/fontawesome-svg-core"
// import {faplus} from "@fortawesome/free-solid-svg-icons"
// library.add(faplus);

createApp(App).use(store).use(router).use(VueQrcodeReader).mount('#app')
